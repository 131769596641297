<template>
  <Modal
    :value="value"
    title="新增养殖区"
    @input="(val) => $emit('input', val)"
    fullscreen
    footer-hide
    @on-visible-change="lohInformationShow"
  >
    <x-table
      :no-data-text="CA('breedArea_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="养殖区名称" prop="name">
          <Input clearable v-model="form.name"></Input>
        </FormItem>
        <FormItem label="养殖区面积" prop="area">
          <Input v-model="form.area">
            <span slot="append">㎡</span>
          </Input>
        </FormItem>
        <FormItem label="养殖区绘制" prop="mapPosition">
          <Input
            placeholder="点击右侧绘制生产区地图"
            v-model="form.mapPosition"
          >
            <span
              slot="append"
              class="iconfont"
              style="cursor: pointer"
              @click="drawMap"
              >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem label="养殖区图片" prop="imgUrl">
          <upload-image
            :clearFlag="!modal.show"
            v-model="form.imgUrl"
            :max="4"
          ></upload-image>
        </FormItem>
        <FormItem label="备注">
          <Input
            type="textarea"
            :rows="3"
            clearable
            v-model="form.remark"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <draw-map-modal
      v-model="drawMapModalShow"
      @draw-finish="drawFinish"
      :backgroundPosition="backgroundPosition"
      :mapPosition="form.mapPosition"
    ></draw-map-modal>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </Modal>
</template>

<script>
import drawMapModal from "./drawMapModal";
import CU from "@/common/util";
import pictureView from "@/components/picture_view";
import { Poptip, Input, Button } from "view-design";

export default {
  name: "",
  components: {
    drawMapModal,
    pictureView,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    farm: Object,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "养殖区图片",
            minWidth: 150,
            render: (h, { row }) => {
              if (!row.imgUrl) {
                return <span>--</span>;
              }
              return (
                <div style="display: flex">
                  {row.imgUrl.split(",").map((item) => {
                    return (
                      <img
                        src={this.getImgUrl(row.servicePath, item)}
                        onClick={() => this.lookPicture(row.servicePath + item)}
                        style="margin-right: 10px;width:30px;height:30px;cursor:pointer"
                      />
                    );
                  })}
                </div>
              );
            },
          },
          {
            title: "养殖区名称",
            minWidth: 110,
            key: "name",
          },
          {
            title: "所属养殖场",
            minWidth: 110,
            key: "farmName",
          },
          {
            title: "养殖区面积",
            minWidth: 110,
            render: (h, { row }) => {
              return <span>{row.area + "㎡"}</span>;
            },
          },
          {
            title: "存栏量",
            minWidth: 100,
            key: "count",
          },
          {
            title: "均重(kg)",
            width: 100,
            key: "avgWeight",
          },
          {
            title: "备注",
            minWidth: 100,
            key: "remark",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              if (!this.weightPop["a" + row.id]) {
                this.$set(this.weightPop, "a" + row.id, false);
              }
              return (
                <div>
                  <Poptip
                    value={this.weightPop["a" + row.id]}
                    on-input={(val) => (this.weightPop["a" + row.id] = val)}
                    on-on-popper-hide={this.clearWeight}
                    transfer
                  >
                    <a style="margin-right: 10px">均重</a>
                    <div slot="content">
                      <Input
                        value={this.weight}
                        on-input={(val) => (this.weight = val)}
                      >
                        <span slot="append">kg/头</span>
                      </Input>
                      <div style="text-align:center;padding-top:12px">
                        <Button
                          on-click={() => this.addWeight(row)}
                          type="primary"
                        >
                          提交
                        </Button>
                      </div>
                    </div>
                  </Poptip>
                  {this.CA("breedArea_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("breedArea_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
        siteType: "2",
        imgUrl: "",
      },
      rules: {
        name: [{ required: true, message: "请填写养殖区名称" }],
        area: [
          { required: true, message: "请填写养殖区面积" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的面积",
          },
        ],
        mapPosition: [{ required: true, message: "请勾画养殖区" }],
      },
      drawMapModalShow: false,
      // 地图背景范围
      backgroundPosition: null,
      search_data: {},
      picture: {
        show: false,
        src: "",
      },

      // 设置均重的保存值
      weight: "",
      weightPop: {},
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增养殖区",
            ca: "breedArea_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "养殖区名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    clearWeight() {
      this.weight = "";
    },
    addWeight(row) {
      this.$post(this.$api.MANURE_PRODUCTION_MODEL.AVG_WEIGHT, {
        sceneId: row.id,
        avgWeight: this.weight,
      }).then((res) => {
        this.$Message.success("设置成功");
        this.weightPop["a" + row.id] = false;
        this.getList();
      });
    },
    add() {
      this.modal = {
        show: true,
        title: "新增养殖区",
        submitLoading: false,
      };
    },
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    drawMap() {
      let backgroundPosition = [];
      let mapPosition = JSON.parse(this.farm.mapPosition);
      mapPosition &&
        Array.isArray(mapPosition) &&
        backgroundPosition.push(...mapPosition);
      this.table.data.forEach((item) => {
        if (this.form.id && item.id == this.form.id) return;
        let tempPosition = JSON.parse(item.mapPosition);
        tempPosition &&
          Array.isArray(tempPosition) &&
          backgroundPosition.push(...tempPosition);
      });
      this.backgroundPosition = backgroundPosition;
      // if (this.table.data.length > 0) {
      //   let m = this.table.data
      //     // .filter((el) => el.farmId == this.farm.id)
      //     .map((item) => JSON.parse(item.mapPosition));
      //   // let features = scene.map((item) => {
      //   //   item.features.forEach((el) => {
      //   //     el.properties.editArea = false;
      //   //   });
      //   //   return item;
      //   // });
      //   // let m = [];
      //   // features.forEach((item) => {
      //   //   m = m.concat(item.features);
      //   // });
      //   let n = farm.mapPosition;
      //   n.features.push(...m);
      //   farm.mapPosition = n;
      // }
      // if (!this.form.mapPosition) {
      //   let farm = { ...this.farm };
      //   let mapPosition = JSON.parse(farm.mapPosition);
      //   farm.mapPosition = mapPosition;
      //   if (this.table.data.length > 0) {
      //     let m = this.table.data
      //       // .filter((el) => el.farmId == this.farm.id)
      //       .map((item) => JSON.parse(item.mapPosition));
      //     // let features = scene.map((item) => {
      //     //   item.features.forEach((el) => {
      //     //     el.properties.editArea = false;
      //     //   });
      //     //   return item;
      //     // });
      //     // let m = [];
      //     // features.forEach((item) => {
      //     //   m = m.concat(item.features);
      //     // });
      //     let n = farm.mapPosition;
      //     n.features.push(...m);
      //     farm.mapPosition = n;
      //   }
      //   this.form.mapPosition = JSON.stringify(farm.mapPosition);
      // }
      this.drawMapModalShow = true;
    },
    drawFinish(data) {
      this.backgroundPosition = null;
      this.form.mapPosition = JSON.stringify(data);
      let center = data.length > 0 ? JSON.parse(data[0].options.center) : {};
      this.form.thirdLongLat = center.lng + "," + center.lat;
      this.drawMapModalShow = false;
    },

    getList() {
      if (!this.CA("breedArea_check")) return;
      this.table.loading = true;
      this.$post(this.$api.PRODUCTION_AREA.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "2",
        farmId: this.farm.id,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      // let backgroundPosition = [];
      // let mapPosition = JSON.parse(this.farm.mapPosition);
      // backgroundPosition.push(...mapPosition);
      // this.table.data.forEach((item) => {
      //   if (item.id != row.id)
      //     backgroundPosition.push(...JSON.parse(item.mapPosition));
      // });
      // this.backgroundPosition = backgroundPosition;
      // let features = JSON.parse(row.mapPosition).features.map((item) => {
      //   item.properties.editArea = true;
      //   return item;
      // });
      // mapPosition.features.push(...features);
      // this.form.mapPosition = JSON.stringify(mapPosition);
      row.imgUrl &&
        (this.form.imgUrl = row.imgUrl
          .split(",")
          .map((item) => this.getImgUrl(row.servicePath, item))
          .join(","));
      this.modal = {
        show: true,
        title: "编辑养殖区",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.PRODUCTION_AREA.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    lohInformationShow(visible) {
      if (visible) {
        this.getList();
      }
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
        siteType: "2",
        imgUrl: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      // let n = JSON.parse(this.form.mapPosition);
      // let features = n.features.filter(
      //   (item) => item.properties.editArea != false
      // );
      // if (features.length == 0) {
      //   this.form.mapPosition = "";
      // } else {
      //   n.features = features;
      //   this.form.mapPosition = JSON.stringify(n);
      // }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.farm.id;
        this.form.imgUrl &&
          (params.imgUrl = this.form.imgUrl
            .split(",")
            .map((item) => "image" + item.split("image")[1])
            .join(","));
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.PRODUCTION_AREA.UPDATE
            : this.$api.PRODUCTION_AREA.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      };
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
/deep/ td.ivu-table-expanded-cell {
  background: #efefef;
}
</style>